import React, {useEffect, useState, useCallback} from "react";
import {useHistory, useParams} from "react-router-dom";
import {makeStyles, Grid, Box, Typography, IconButton, Icon} from "@material-ui/core";
import * as _ from 'lodash';

import * as Utils from "../../../Utils";
import AdminBaseTemplate from "../../../templates/AdminBaseTemplate";
import * as Styles from "../../../components/admin/Styles";
import EventTitleValueDisplay from "../../../components/admin/EventTitleValueDisplay";
import EventUserLocationMap from "../../../components/admin/EventUserLocationMap";
import EventStore from "../../../stores/EventStore";
import DescribedButton from "../../../components/admin/DescribedButton";


export default function EventStatusMainScreen() {
    const classes = useStyles();
    const history = useHistory();
    const params = useParams();
    const eventId = params["event_id"];

    const [showLoading, setShowLoading] = useState(false);
    const [title, setTitle] = useState('イベントステータス');
    const [eventData, setEventData] = useState({});
    const [checkPointList, setCheckPointList] = useState([]);
    const [kmlFileList, setKmlFileList] = useState({});
    const [usersLocation, setUsersLocation] = useState([]);

    // 初期処理
    useEffect(() => {
        (async () => {
            setShowLoading(true);

            // イベント情報検索
            const event = await EventStore.retrieveEvent(eventId);
            setTitle(event["name"] + ' - イベントステータス');
            setEventData(event);

            // KMLファイルリスト作成
            const kmlFiles = {};
            if (event['road_book_extras'] && _.isObject(event['road_book_extras'])) {
                for (const [key, value] of Object.entries(event['road_book_extras'])) {
                    kmlFiles[key] = value['kml'];
                }
            }
            setKmlFileList(kmlFiles);

            // チェックポイントサマリーデータ検索
            await retrieveDisplayData(event);

            setShowLoading(false);
        })();

        // 定期的な再読み込み
        const intervalId = setInterval(() => retrieveDisplayData(eventData), 1000 * 60);

        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 画面読み込み
    const retrieveDisplayData = useCallback(async (passedEventData) => {
        try {
            setShowLoading(true);

            // 各チェックポイントの通過状態サマリーデータを取得
            // console.log(eventId, eventData);
            const checkPointSummaryData = await EventStore.retrieveCheckPointPassedSummary(eventId);
            setCheckPointList(checkPointSummaryData);

            // ユーザー現在位置取得
            // ただしユーザー現在位置非表示フラグが立っていれば取得しない
            if (await EventStore.retrieveEventUserLocationHiddenFlag(eventId)) {
                setUsersLocation([]);
            } else {
                const usersLocationList =
                    await EventStore.retrieveUsersCurrentLocation(eventId, !!passedEventData['is_controlled_event']);
                setUsersLocation(usersLocationList);
            }

        } catch (e) {
            console.error(e);
        } finally {
            setShowLoading(false);
        }
    }, [eventId]);

    // リフレッシュボタン
    const onRefreshClick = async () => {
        await retrieveDisplayData(eventData);
    };

    return (
        <AdminBaseTemplate
            title={title}
            isShowLoadingModal={showLoading}
            rightButton={(
                <IconButton
                    variant="contained"
                    color="inherit"
                    onClick={onRefreshClick}
                >
                    <Icon>refresh</Icon>
                </IconButton>
            )}
        >
            <Grid container className={classes.container} spacing={2}>
                {eventData["is_controlled_event"] && (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6">CPステータス(ユーザー毎)</Typography>
                            <DescribedButton
                                title="ユーザー毎 CPステータス 確認"
                                description=""
                                onClick={() => {
                                    history.push(`/event/${eventData["id"]}/check_point_status_by_user`)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6">CPステータス(CP毎)</Typography>
                            {checkPointList.map((checkPoint) => (
                                <EventTitleValueDisplay
                                    key={checkPoint["id"]}
                                    title={checkPoint["name"]}
                                    primaryValue={
                                        checkPoint["checked_in_user_count"] + "/" + checkPoint["target_user_count"] + "名 通過"}
                                    secondaryValue={Utils.formatCheckPointFromToTime(checkPoint["open_window"]) || ""}
                                    onPrimaryClick={(e) => {
                                        e.preventDefault();
                                        history.push(`/event/${eventId}/check_point_register/${checkPoint["id"]}`);
                                    }}
                                />
                            ))}
                            <small>※スタッフ除く（スタッフがチェックインするとチェックイン済み人数が狂います）</small>
                        </Grid>
                    </>
                )}

                <Grid item xs={12} style={{marginBottom:'3rem'}}>
                    <Box>
                        {!!eventData["id"] && (
                            <EventUserLocationMap
                                eventId={eventId}
                                initialCenter={!!eventData["start_point"] ?
                                    Utils.convLatLng(eventData["start_point"]["coords"]) : null}
                                kmlFileList={kmlFileList}
                                userDataList={usersLocation}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
        </AdminBaseTemplate>
    );
};

const useStyles = makeStyles((theme) => ({
    container: Styles.container,
}));
