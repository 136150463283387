const dealerDataBase = [
    // [OFF]from, to, individual, [ON]from, to, individual, dealer
    [1,21,null,null,null,null,"M.S.C.HARA"],
    [22,31,null,null,null,[1],"Motorrad 埼玉シティ"],
    [null,null,[32],null,null,null,"Motorrad 飛鳥山"],
    [33,50,null,null,null,null,"Motorrad 世田谷"],
    [51,61,null,2,3,null,"Motorrad 柏"],
    [null,null,[62],null,null,null,"Motorrad 杉並"],
    [63,66,null,null,null,null,"Motorrad 港北"],
    [null,null,[67],null,null,null,"Motorrad 川越"],
    [68,69,null,null,null,null,"Motorrad 京葉"],
    [null,null,null,null,null,null,"Motorrad 八千代"],
    [70,71,null,null,null,null,"Motorrad 東京ベイ"],
    [72,91,[205,214,215],null,null,[4],"Motorrad 羽田"],
    [92,115,null,5,11,[19],"A-big Motorrad"],
    [116,121,null,null,null,[12],"Motorrad 八王子"],
    [122,123,null,null,null,null,"Motorrad 横浜"],
    [124,128,null,13,14,null,"Motorrad 湘南台"],
    [129,144,null,null,null,[15],"Motorrad 相模原"],
    [null,null,[145],null,null,null,"Motorrad 平塚"],
    [null,null,null,null,null,[16],"Motorrad 筑波"],
    [146,149,null,null,null,null,"Motorrad 水戸"],
    [150,151,null,null,null,null,"Motorrad 宇都宮"],
    [152,161,null,null,null,null,"モトパーク"],
    [162,164,null,null,null,null,"Motorrad 高崎"],
    [165,168,null,null,null,null,"Motorrad 長野"],
    [169,173,null,null,null,null,"Motorrad 長野シティ"],
    [174,179,null,null,null,null,"Motorrad 豊田"],
    [null,null,null,null,null,null,"Motorrad 静岡"],
    [180,190,null,null,null,null,"Motorrad アルファ"],
    [191,194,null,17,18,null,"DATZ名古屋"],
    [195,196,null,null,null,null,"モトアートササキ"],
    [197,198,null,null,null,null,"Motorrad 京都"],
    [199,203,null,null,null,null,"Motorrad 鈴鹿"],
    [206,213,null,null,null,null,"BMW Japan"],
];

function gsDaysGenerateNumberDealer(isOff = true) {
    let fromIdx = 0;
    let toIdx = 1;
    let individualIdx = 2;
    if (!isOff) {
        fromIdx = 3;
        toIdx = 4;
        individualIdx = 5
    }

    const numbers = {};
    for (let i = 0; i < dealerDataBase.length; i++) {
        const dealerData = dealerDataBase[i];
        if (dealerData[fromIdx] && dealerData[toIdx]) {
            for (let j = dealerData[fromIdx]; j <= dealerData[toIdx]; j++) {
                numbers["" + j] = dealerData[6];
            }
        }
        if (dealerData[individualIdx]) {
            dealerData[individualIdx].forEach((num) => {
                numbers["" + num] = dealerData[6];
            });
        }
    }
    return numbers;
}
export const gsDaysNumberDealerOff = gsDaysGenerateNumberDealer(true);
export const gsDaysNumberDealerOn = gsDaysGenerateNumberDealer(false);
